import axios from "axios";
import {get} from "../utils";
import Config from "../config";
import {toast} from "react-toastify";

let instance = axios.create({
    baseURL: 'http://44.226.238.61/',
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 10000,
});
instance.interceptors.request.use(function (config) {
    const token = get("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
});

const API = {
    getOrders: async () => {
        return instance
            .get(Config.GET_ORDERS)
            .then((res) => {
                if (res && res.data) {
                    return res.data.data;
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    },
    getArchivedOrders: async () => {
        return instance
            .get(Config.GET_ARCHIVED_ORDERS)
            .then((res) => {
                if (res && res.data) {
                    return res.data.data;
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    },
    deleteArchivedOrder: async (id) => {
        return instance
            .delete(Config.GET_ARCHIVED_ORDERS + `/${id}`)
            .then((res) => {
                if (res && res.data) {
                    return res.data.data;
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    },
    deleteOrder: async (id) => {
        return instance
            .delete(Config.GET_ORDERS + `/${id}`)
            .then((res) => {
                if (res && res.data) {
                    return res.data.data;
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    },
    messageOrder: async (id) => {
        return instance
            .post(Config.POST_MESSAGE_ORDERS + `/${id}`)
            .then((res) => {
                if (res && res.data) {
                    return res.data.data;
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    },
    updateOrders: async (data) => {
        return instance
            .post(Config.UPDATE_ORDERS, data)
            .then((res) => {
                toast.success("Order has been updated")
                return res;
            })
            .catch((err) => {
                toast.error("Something went wrong.")
                return err;
            });
    },
    cancelOrders: async (id) => {
        return instance
            .post(`${Config.GET_ORDERS}/${id}/cancel`)
            .then((res) => {
                if (res && res.data) {
                    return res.data.data;
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    },
    getOrderDetails: async () => {
        const id = localStorage.getItem("orderId");
        return instance
            .get(`${Config.GET_ORDERS}/${id}`)
            .then((res) => {
                if (res && res.data) {
                    return res.data.data;
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    },
    addOrderImages: async (values) => {
        const id = localStorage.getItem("orderId");
        const data = {...values};
        data.order_id = id;

        return instance
            .post(Config.ORDER_IMAGES, data)
            .then((res) => {
                if (res && res.data) {
                    return res.data.data;
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    },
    downloadInvoice: async () => {
        const order_id = localStorage.getItem("orderId");
        return instance
            .post(`${Config.DOWNLOAD_INVOICE}`,{order_id}, {responseType: 'blob'})
            .then((res) => {
                if (res && res.data) {
                    const href = URL.createObjectURL(res.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `invoice_${order_id}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);

                    return true;
                }
                return false;
            })
            .catch((err) => {
                return err;
            });
    },
    resendInvoice: async () => {
        const order_id = localStorage.getItem("orderId");
        return instance
            .post(`${Config.RESEND_INVOICE}`,{order_id}, {responseType: 'blob'})
            .then((res) => {
                toast.success("Invoice has been sent to email");
                return res.data;
            })
            .catch((err) => {
                toast.error("Something went wrong.");
                return err;
            });
    },
    deleteOrderImage: async (id) => {
        return instance
            .delete(`${Config.ORDER_IMAGE}/${id}`)
            .then((res) => {
                if (res && res.data) {
                    return res.data.data;
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    },
    updateOrderTransporter: async (value) => {
        const id = localStorage.getItem("orderId");
        const data = {
            ...value,
            order_id: id,
        };
        return instance
            .post(Config.UPDATE_ORDER_TRANSPORTER, data)
            .then((res) => {
                if (res && res.data) {
                    return res.data.data;
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    }
}

export default API