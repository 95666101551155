import {createAsyncThunk} from "@reduxjs/toolkit";
import API from "../services/orders";
import {
    DELETE_ORDERS,
    GET_ORDERS_BY_ID,
    GET_ORDERS,
    ORDER_DETAILS,
    SEARCH_ORDERS,
    UPDATE_ORDERS,
    UPDATE_ORDER_TRANSPORTER,
    GET_ARCHIVED_ORDERS,
    SEARCH_ARCHIVED_ORDERS,
    DELETE_ORDER_IMAGE,
    DOWNLOAD_INVOICE,
    ADD_ORDER_IMAGES,
    RESEND_INVOICE,
    SET_FORM_VALUES,
    SET_ORDER_DATE,
    ORDER_ITEM,
    POST_MESSAGE_ORDERS
} from "../types/OrdersConstant";

export const getOrdersLists = createAsyncThunk(
    GET_ORDERS, async (data) => {
        return await API.getOrders(data)
    },
)
export const getArchivedOrders = createAsyncThunk(
    GET_ARCHIVED_ORDERS, async (data) => {
        return await API.getArchivedOrders(data)
    },
)

export const getOrderById = createAsyncThunk(
    GET_ORDERS_BY_ID, async (data) => {
        return await API.getOrderDetails(data)
    },
)
export const addOrderImages = createAsyncThunk(
    ADD_ORDER_IMAGES, async (data) => {
        return await API.addOrderImages(data)
    },
)
export const downloadInvoice = createAsyncThunk(
    DOWNLOAD_INVOICE, async (data) => {
        return await API.downloadInvoice(data)
    },
)
export const resendInvoice = createAsyncThunk(
    RESEND_INVOICE, async (data) => {
        return await API.resendInvoice(data)
    },
)
export const deleteOrderImage = createAsyncThunk(
    DELETE_ORDER_IMAGE, async (data) => {
        return await API.deleteOrderImage(data)
    },
)

export const cancelOrders = createAsyncThunk(
    GET_ORDERS, async (id) => {
        return await API.cancelOrders(id)
    },
)
export const orderDetails = createAsyncThunk(
    ORDER_DETAILS, async (data) => {
        return data
    },
)
export const setFormValues = createAsyncThunk(
    SET_FORM_VALUES, async (data) => {
        return data
    },
)
export const setOrderDate = createAsyncThunk(
    SET_ORDER_DATE, async (data) => {
        return {...data}
    },
)
export const updateOrders = createAsyncThunk(
    UPDATE_ORDERS, async (data) => {
        return await API.updateOrders(data)
    },
)
export const updateOrderTransporter = createAsyncThunk(
    UPDATE_ORDER_TRANSPORTER, async (data) => {
        return await API.updateOrderTransporter(data)
    },
)
export const deleteOrder = createAsyncThunk(
    DELETE_ORDERS, async (id) => {
        return await API.deleteOrder(id)
    },
)

export const messageOrder = createAsyncThunk(
    POST_MESSAGE_ORDERS, async (id) => {
        return await API.messageOrder(id)
    },
)

export const deleteArchivedOrder = createAsyncThunk(
    DELETE_ORDERS, async (id) => {
        return await API.deleteArchivedOrder(id)
    },
)

export const searchOrders = createAsyncThunk(
    SEARCH_ORDERS, async (filterField = null) => filterField
)
export const orderItem = createAsyncThunk(
    ORDER_ITEM, async (filterField = null) => filterField
)
export const searchArchivedOrders = createAsyncThunk(
    SEARCH_ARCHIVED_ORDERS, async (filterField = null) => filterField
)