export const GET_ORDERS = 'GET_ORDERS';
export const GET_ARCHIVED_ORDERS = 'GET_ARCHIVED_ORDERS';
export const GET_ORDERS_BY_ID = 'GET_ORDERS_BY_ID';
export const ADD_ORDER_IMAGES = 'ADD_ORDER_IMAGES';
export const DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE';
export const RESEND_INVOICE = 'RESEND_INVOICE';
export const ORDER_DETAILS = 'ORDER_DETAILS';
export const SET_FORM_VALUES = 'SET_FORM_VALUES';
export const SET_ORDER_DATE = 'SET_ORDER_DATE';
export const UPDATE_ORDERS = 'UPDATE_ORDERS';
export const UPDATE_ORDER_TRANSPORTER = 'UPDATE_ORDER_TRANSPORTER';
export const DELETE_ORDERS = 'DELETE_ORDERS';
export const SEARCH_ORDERS = 'SEARCH_ORDERS';
export const ORDER_ITEM = 'ORDER_ITEM';
export const SEARCH_ARCHIVED_ORDERS = 'SEARCH_ARCHIVED_ORDERS';
export const DELETE_ORDER_IMAGE = 'DELETE_ORDER_IMAGE';
export const POST_MESSAGE_ORDERS = 'POST_MESSAGE_ORDERS';