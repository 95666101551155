import {createSlice} from "@reduxjs/toolkit";
import {
    getArchivedOrders,
    getOrderById,
    getOrdersLists,
    orderDetails, orderItem,
    searchArchivedOrders,
    searchOrders,
    setFormValues, setOrderDate
} from "../actions/orders";

const initialState = {
    orderListData: [],
    archivedOrdersData: [],
    filteredOrderList: [],
    filteredArchivedOrder: [],
    searchQuery: '',
    orderDetailData: null,
    orderFormValues: null,
    pageItem:10,
    orderDates: null,
    loading: false
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {},
    extraReducers: {
        [getOrdersLists.pending]: (state) => {
            state.loading = true;
        },
        [getOrdersLists.fulfilled]: (state, action) => {
            state.orderListData = action.payload;
            state.loading = false;
        },
        [getArchivedOrders.pending]: (state) => {
            state.loading = true;
        },
        [getArchivedOrders.fulfilled]: (state, action) => {
            state.archivedOrdersData = action.payload;
            state.loading = false;
        },
        [orderDetails.fulfilled]: (state, action) => {
            state.orderDetailData = action.payload;
            state.loading = false;
        },
        [setFormValues.fulfilled]: (state, action) => {
            state.orderFormValues = action.payload;
        },
        [setOrderDate.fulfilled]: (state, action) => {
            state.orderDates = action.payload;
        },
        [searchOrders.fulfilled]: (state, action) => {
            state.searchQuery = action.payload?.toLowerCase() || "";
        },
        [orderItem.fulfilled]: (state, action) => {
            state.pageItem = action.payload || 10;
        },
        [searchArchivedOrders.fulfilled]: (state, action) => {
            const searchQuery = action.payload.orderId?.toLowerCase() || "";
            state.filteredArchivedOrder = state.orderListData.filter((order) => {
                const contactName = `${order.contact_details.first_name} ${order.contact_details.last_name}`.toLowerCase();
                const orderCity = order.contact_details.city.toLowerCase();
                const orderId = order.order_id.toLowerCase();
                const driverName = order.vehicle?.vehicle_driver?.driver?.name?.toLowerCase() || "";

                return (
                    orderId.includes(searchQuery) ||
                    orderCity.includes(searchQuery) ||
                    contactName.includes(searchQuery) ||
                    driverName.includes(searchQuery)
                );
            });
        },
        [getOrderById.pending]: (state, action) => {
            state.loading = true;
        },
        [getOrderById.fulfilled]: (state, action) => {
            state.orderDetailData = action.payload;
            state.loading = false;
        }
    }
})

export default orderSlice.reducer;